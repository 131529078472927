<button [class.filter-active]="selected.length > 0" [matMenuTriggerFor]="statusFilter" class="small-icon-button"
        mat-icon-button>
  <mat-icon *ngIf="selected.length === 0">filter_list</mat-icon>
  <mat-icon *ngIf="selected.length > 0">filter_alt</mat-icon>
</button>
<mat-menu #statusFilter="matMenu" class="check-box-filter">
  <div (click)="checkboxChanged(o.value); $event.stopPropagation(); cb._onInputClick()"
       (keydown.enter)="$event.stopPropagation(); cb._onInputClick()"
       (keydown.space)="$event.stopPropagation(); cb._onInputClick()"
       *ngFor="let o of options" mat-menu-item>
    <mat-checkbox #cb [checked]="selected.includes(o.value)">
      <ng-container *ngIf="user">
        <app-user [email]="o.value"></app-user>
      </ng-container>
      <ng-container *ngIf="!user">{{ o.display }}</ng-container>
    </mat-checkbox>
  </div>
  <button (click)="clear()"
          [disabled]="selected.length === 0"
          mat-menu-item>
    <mat-icon>filter_alt_off</mat-icon>
    <span>Clear filter</span>
  </button>
</mat-menu>
