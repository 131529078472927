import {Injectable, signal, WritableSignal} from '@angular/core';
import {catchError, Observable, of, shareReplay, tap} from "rxjs";
import {CookieService} from "ngx-cookie-service";
import {
  LoginRequest,
  LoginResponse,
  PasswordResetRequest,
  UserCreateUpdateRequest,
  UserDetailed,
  UserInfo,
  UserRegistrationRequest,
  UserShortResponse
} from "./model/user";
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  currentUser: UserInfo | null | undefined;
  currentUser$: Observable<UserInfo | null> | undefined;
  cachedUsers$: Observable<UserShortResponse[]> | undefined;
  isLoggedInSignal: WritableSignal<{ value: boolean } | null> = signal(null);
  token: string | undefined;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
  ) {
    try {
      const currentUserFromCookies = JSON.parse(cookieService.get("currentUser"));
      console.log("AuthService currentUserFromCookies", currentUserFromCookies);
      if (currentUserFromCookies.email != undefined) {
        console.log("AuthService set token from cookies");
        this.token = cookieService.get("token")
      }
    } catch (e) {
    }
  }

  getCurrentUser(): Observable<UserInfo | null> {
    if (this.currentUser !== undefined) return of(this.currentUser);

    if (this.currentUser$ !== undefined) {
      return this.currentUser$;
    }

    const obs = this.http.get<UserInfo>(environment.apiUrl + "/user")
      .pipe(
        tap(u => {
          this.currentUser = u;
          this.isLoggedInSignal.set({value: true});
        }),
        catchError((err, caught) => {
          this.currentUser = null;
          this.isLoggedInSignal.set({value: false});
          return of(null);
        })
      );
    this.currentUser$ = obs;
    return obs;
  }

  getAuthorizationToken(): string {
    return this.token || "";
  }

  getAllUsers(ignoreCache: Boolean = false): Observable<UserShortResponse[]> {
    if (ignoreCache || !this.cachedUsers$) {
      this.cachedUsers$ = this.http.get<UserShortResponse[]>(environment.apiUrl + "/user/all")
        .pipe(shareReplay(1));
    }
    return this.cachedUsers$;
  }

  createUser(request: UserCreateUpdateRequest): Observable<UserShortResponse> {
    return this.http.post<UserShortResponse>(environment.apiUrl + "/user", request);
  }

  editUser(userId: string, request: UserCreateUpdateRequest): Observable<UserShortResponse> {
    return this.http.put<UserShortResponse>(environment.apiUrl + "/user/" + userId, request);
  }

  // TODO store the URL so we can redirect after logging in
  // redirectUrl: string | null = null;

  login(email: string, password: string): Observable<boolean> {
    const request: LoginRequest = {email: email, password: password};
    return this.http.post<LoginResponse>(environment.apiUrl + "/user/login", request).pipe(
      map(value => {
        this.currentUser = value.user
        this.token = value.token
        this.cookieService.set("currentUser", JSON.stringify(value.user), {path: '/', expires: 7});
        this.cookieService.set("token", value.token, {path: '/', expires: 7});
        this.isLoggedInSignal.set({value: true});
        return true;
      }),
      catchError((err, caught) => of(false))
    )
  }

  getUserDetailed(id: string): Observable<UserDetailed> {
    return this.http.get<UserDetailed>(environment.apiUrl + "/user/" + id);
  }

  getUserByEmailLink(emailLinkId: string): Observable<UserShortResponse> {
    return this.http.get<UserShortResponse>(environment.apiUrl + "/user/link?id=" + emailLinkId);
  }

  register(request: UserRegistrationRequest): Observable<UserShortResponse> {
    return this.http.post<UserShortResponse>(environment.apiUrl + "/user/registration", request);
  }

  requestPasswordReset(email: string): Observable<UserShortResponse> {
    return this.http.post<UserShortResponse>(environment.apiUrl + "/user/password-reset/request?email=" + encodeURIComponent(email), null);
  }

  resetPassword(request: PasswordResetRequest): Observable<UserShortResponse> {
    return this.http.post<UserShortResponse>(environment.apiUrl + "/user/password-reset", request);
  }

  logout(): void {
    this.cookieService.delete("currentUser");
    this.cookieService.delete("token");
    this.currentUser = null;
    this.token = "";
    this.isLoggedInSignal.set({value: false});
  }
}
