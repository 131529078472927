import {ChangeDetectorRef, Component} from '@angular/core';
import {EquipmentCatalogService} from "../../api/equipment-catalog.service";
import {EquipmentCatalog, EquipmentCatalogCreateUpdateRequest} from "../../api/model/equipment-catalog";
import {Papa, ParseResult} from "ngx-papaparse";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HttpErrorResponse} from "@angular/common/http";
import {ApiError} from "../../api/model/common";
import {EMPTY, Observable, Subject, switchMap, tap} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {EquipmentGroup} from "../../api/model/equipment";
import {debounceTime} from "rxjs/operators";
import {ManufacturerService} from "../../api/manufacturer.service";

@Component({
  selector: 'app-equipment-catalog',
  templateUrl: './equipment-catalog.component.html',
  styleUrls: ['./equipment-catalog.component.sass']
})
export class EquipmentCatalogComponent {
  displayedColumns: string[] = ['manufacturer', 'sku', 'model', 'description', 'hyperlink', 'delete'];
  catalog: EquipmentCatalog[] = [];
  catalogGroup: EquipmentGroup = EquipmentGroup.PROJECT;
  catalogGroupEnum: typeof EquipmentGroup = EquipmentGroup;

  exampleCsvData: string;
  apiError: string | undefined;

  imported: {
    file: File,
    equipmentCatalog: EquipmentCatalogCreateUpdateRequest[],
  } | undefined;
  filterLoading: boolean = true;
  displayLimit: number = 100;
  catalog$!: Observable<EquipmentCatalog[]>;
  filterLoaded = "";
  private searchText$ = new Subject<string>();
  private allManufacturers = this.manufacturerService.listSignal();

  constructor(
    private route: ActivatedRoute,
    private equipmentCatalogService: EquipmentCatalogService,
    private manufacturerService: ManufacturerService,
    private papa: Papa,
    private _snackBar: MatSnackBar,
    private _changeDetectorRef: ChangeDetectorRef,
  ) {
    this.catalog$ = this.searchText$.pipe(
      tap(_ => this.filterLoading = true),
      debounceTime(500),
      //distinctUntilChanged(),
      switchMap(searchFor => {
        console.log("EquipmentCatalogComponent loading catalog after 500ms debounce", searchFor)
        this.filterLoaded = searchFor;
        return this.equipmentCatalogService.list(this.catalogGroup, searchFor, this.displayLimit);
      }),
      tap(_ => this.filterLoading = false),
    );

    this.catalog$.subscribe(c => {
      this._changeDetectorRef.markForCheck();
      this.catalog = c;
    });

    this.route.data.subscribe(data => {
      this.catalog = [];
      this.catalogGroup = data['group'] as EquipmentGroup;
      this.route.queryParams.subscribe(params => {
        this.searchText$.next(params["q"] || "");
      });
    });

    const rows = [
      [
        "Manufacturer",
        "SKU",
        "Model",
        "Description",
        "Hyperlink",
      ],
      [
        "Sonus", // 0
        "40.287.039.01", // 1
        "EW-D ASA SPLITTER", // 2
        "Multiline description\nLine 2\nLine 3", // 3
        "https://sonus.lt/", // 4
      ],
    ];
    this.exampleCsvData = "data:text/csv;charset=utf-8," + encodeURI(papa.unparse(rows, {
      delimiter: ";",
      newline: "\r\n"
    }));
    //rows.map(e => e.join(",")).join("\n");
  }

  catalogGroupTitle(): string {
    return this.catalogGroup == EquipmentGroup.SERVICE ? "Service Spare Parts" : "Equipment";
  }

  csvInputChange($event: Event) {
    // TODO add loading spinner
    this.apiError = undefined;
    this.imported = undefined;
    const file = ($event.target as any).files[0] as File;
    file.text().then(contents => {
      const parsed = this.papa.parse(contents, {delimiter: ";"});
      if (parsed.errors.length > 0) {
        this.apiError = parsed.errors.map(e => e.message).join("; ");
        return;
      }
      const request = this.parsedCsvToData(parsed);
      if (request.length === 0) {
        this.apiError = "Bad or empty .csv file submitted. Make sure you use semicolons as separators (;)";
        return;
      }
      const manufacturers = [...new Set([...request.map(row => row.manufacturer)])];
      const allManufacturers = this.allManufacturers() || [];
      const newManufacturers = manufacturers.filter(m => allManufacturers.find(n => n.name === m) === undefined);
      console.log("EquipmentCatalogComponent csvInputChange", $event, file, parsed, request, manufacturers, newManufacturers);
      if (newManufacturers.length === 0 || confirm(`You are about to create ${newManufacturers.length} manufacturer${newManufacturers.length > 1 ? "s" : ""}: ${newManufacturers.join(", ")}. Are you sure you want to continue?`)) {
        this.equipmentCatalogService.bulkWrite(request).subscribe({
          next: (_) => {
            console.log("EquipmentCatalogComponent imported succesfully", request);
            this.imported = {file, equipmentCatalog: request};
            this._snackBar.open(`Great success! ${request.length} items imported to equipment catalog...`, undefined, {duration: 10000});
            this._changeDetectorRef.markForCheck();
            this.reloadCatalog();
          },
          error: error => {
            console.log("EquipmentCatalogComponent API call failed", error)
            this.apiError = ((error as HttpErrorResponse).error as ApiError).message || (error as HttpErrorResponse).message;
            this._changeDetectorRef.markForCheck();
            return EMPTY;
          },
        });
      }
    });
  }

  deleteItem(e: EquipmentCatalog) {
    if (confirm("Are you sure you want to delete item with " + e.sku + " sku from the catalog?")) {
      this.equipmentCatalogService.delete(e.id).subscribe(
        _ => {
          this.reloadCatalog();
        }
      )
    }
  }

  exampleFilename(): string {
    const group = this.catalogGroupTitle().toLowerCase().replaceAll(" ", "-")
    return `example-${group}-catalog-import.csv`
  }

  search(query: string) {
    this.searchText$.next(query);
  }

  getValue(event: KeyboardEvent): string {
    return (event.target as HTMLInputElement).value;
  }

  private parsedCsvToData(csv: ParseResult): EquipmentCatalogCreateUpdateRequest[] {
    const csvNoHeaders = (csv.data.slice(1) as string[][]).filter(line => line.length > 2 && line[0] != "");

    return csvNoHeaders.map(line => {
      return {
        group: this.catalogGroup,
        manufacturer: line[0],
        sku: line[1],
        model: line[2],
        description: line[3],
        hyperlink: line[4],
      };
    });
  }

  private reloadCatalog() {
    this.searchText$.next(this.filterLoaded);
  }
}
