@if (projects) {
  <as-split direction="horizontal" unit="pixel" class="sidenav-container" useTransition="true"
            (dragEnd)="onSidenavDragEnd($event)">
    <as-split-area minSize="150" [size]="sidenavInitialSize" maxSize="600" [visible]="sidenavExpanded" class="sidenav">
      <app-project-list (updated)="onUpdate()" [editable]="true"
                        [projects]="projects" [showDeadline]="false"></app-project-list>
      <div class="footer">
        <button (click)="openAddTopLevelProjectDialog()" mat-button>
          <mat-icon>add</mat-icon>
          Add top-level project
        </button>
      </div>
    </as-split-area>
    <as-split-area size="*" class="sidenav-content">
      <div class="toggle-button-container">
        <button (click)="toggleSidenav()" aria-label="Collapse/expand" class="toggle-button" color="primary"
                mat-mini-fab>
          <mat-icon *ngIf="sidenavExpanded">menu_open</mat-icon>
          <mat-icon *ngIf="!sidenavExpanded">menu</mat-icon>
        </button>
      </div>
      <router-outlet></router-outlet>
    </as-split-area>
  </as-split>
} @else {
  <p>Loading projects...</p>
  <!-- TODO prettify loading screens -->
}
