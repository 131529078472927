.title
  display: inline-flex
  padding-left: 8px

  .logo-colored
    display: none

  img
    width: 60px
    height: 60px

.grid-container:not(:has(.sidenav-container))
  padding: 12px
  display: block

.version
  position: fixed
  bottom: 4px
  right: 4px
  padding: 2px
  background: rgba(0, 0, 0, 0.2)
  color: #666
  z-index: 1000

  &:has(.new)
    color: #cda76d

mat-toolbar.toolbar
  mat-toolbar-row
    padding-left: 4px

  .search-field
    display: none
    margin-left: 8px

    &.search-visible
      display: block

  .small-spacer
    margin-left: 15px

  .big-spacer
    margin-left: 30px

  a
    text-decoration: none

  a:hover
    text-decoration: underline

  .current-user-name
    text-transform: capitalize

.mat-mdc-menu-panel.alerts
  width: 480px
  max-width: 480px !important

  .mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item-text
    flex: 1

  mat-divider
    margin-top: 12px

  h4.snoozed
    padding: 0 12px
