import {Authored} from "./common";

export interface Manufacturer {
  id: string;
  name: string;
  warrantyInMonths: number | null;
  hasSerialNumberByDefault: boolean | null;
  responsible: string[];
  model: string;
  created: Authored;
  updated: Authored;
}

export interface ManufacturerCreateUpdateRequest {
  name: string;
  warrantyInMonths: number;
  hasSerialNumberByDefault: boolean;
  responsible: string[];
}

export interface ManufacturerResponse {
  name: string;
  foundIn: ManufacturerFoundIn[];
  assigned: Manufacturer | null;
}

export enum ManufacturerFoundIn {
  CATALOG = "CATALOG",
  EQUIPMENT = "EQUIPMENT",
}
