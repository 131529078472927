<h1 mat-dialog-title>
  Changing permissions for {{ project.nameWithoutParents }}
</h1>
<div mat-dialog-content>

  <table [dataSource]="table" class="dense-minimum" mat-table>
    <ng-container matColumnDef="user">
      <th *matHeaderCellDef class="user" mat-header-cell>User</th>
      <td *matCellDef="let row" mat-cell>
        <app-user [email]="row.user"></app-user>
      </td>
    </ng-container>

    <ng-container *ngFor="let role of projectRoles" [matColumnDef]="role">
      <th *matHeaderCellDef class="role" mat-header-cell>
        <div class="rotate"><span [title]="projectRoleDescriptions[role]">{{ projectRoleDescriptions[role] }}</span>
        </div>
      </th>
      <td *matCellDef="let row" mat-cell>
        <mat-checkbox (change)="setRole(row, role, $event.checked)" [checked]="hasRole(row, role)"
                      [disabled]="isRoleOverridden(row, role)"></mat-checkbox>
      </td>
    </ng-container>

    <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
    <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
  </table>

</div>
<div mat-dialog-actions>
  <button (click)="onCancelClick()" mat-button>Cancel</button>
  <button (click)="onSubmit()" color="primary" mat-raised-button>Save</button>
  <mat-error *ngIf="apiError" class="api-error">
    {{ apiError }}
  </mat-error>
</div>
