import {Injectable, signal, Signal, WritableSignal} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable, tap} from "rxjs";
import {environment} from "../../environments/environment";
import {Manufacturer, ManufacturerCreateUpdateRequest, ManufacturerResponse} from "./model/manufacturer";
import {map} from "rxjs/operators";

@Injectable()
export class ManufacturerService {
  private manufacturersSignal: WritableSignal<Manufacturer[] | undefined> = signal(undefined)
  private loaded = false;

  constructor(private http: HttpClient) {
  }

  listSignal(): Signal<Manufacturer[] | undefined> {
    if (!this.loaded) {
      this.loaded = true;
      this.list().subscribe();
    }
    return this.manufacturersSignal;
  }

  list(): Observable<Manufacturer[]> {
    return this.http.get<Manufacturer[]>(environment.apiUrl + "/equipment/manufacturer").pipe(
      map(list => list.sort((a, b) => a.name.localeCompare(b.name, "lt", {sensitivity: "base"}))),
      tap(value => {
        console.log("ManufacturerService loaded list and signal set");
        this.loaded = true;
        this.manufacturersSignal.set(value);
      }),
    );
  }

  listDetailed(): Observable<ManufacturerResponse[]> {
    return this.http.get<ManufacturerResponse[]>(environment.apiUrl + "/equipment/manufacturer/detailed")
      .pipe(map(list => list.sort((a, b) => a.name.localeCompare(b.name))))
  }

  update(request: ManufacturerCreateUpdateRequest): Observable<any> {
    return this.http.put(environment.apiUrl + "/equipment/manufacturer", request)
  }

  delete(id: string): Observable<any> {
    return this.http.delete(environment.apiUrl + "/equipment/manufacturer/" + id)
  }
}
