import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {EquipmentCatalog, EquipmentCatalogCreateUpdateRequest} from "./model/equipment-catalog";
import {EquipmentGroup} from "./model/equipment";

@Injectable()
export class EquipmentCatalogService {
  constructor(private http: HttpClient) {
  }

  list(group: EquipmentGroup = EquipmentGroup.PROJECT, filter: string = "", limit: number = 0): Observable<EquipmentCatalog[]> {
    return this.http.get<EquipmentCatalog[]>(environment.apiUrl + `/equipment/catalog`, {
      params: {
        group: group,
        q: filter,
        limit: limit,
      }
    })
  }

  bulkWrite(request: EquipmentCatalogCreateUpdateRequest[]): Observable<any> {
    return this.http.put(environment.apiUrl + "/equipment/catalog", request)
  }

  delete(id: string): Observable<any> {
    return this.http.delete(environment.apiUrl + "/equipment/catalog/" + id)
  }
}
