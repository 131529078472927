<div [cdkContextMenuTriggerFor]="editable ? contextMenu : null" [ngClass]="project.status"
     class="project-list-item single-group">
  <span (click)="toggleProjectBookmark(project)" class="detail-bit bookmark">
    <a>
      <mat-icon *ngIf="!project.bookmarked" class="small-icon">bookmark_outlined</mat-icon>
      <mat-icon *ngIf="project.bookmarked" class="small-icon">bookmark</mat-icon>
    </a>
  </span>
  <app-project-status [condense]="true" [project]="project"></app-project-status>

  <a [routerLink]="project | url" [title]="project.nameWithoutParents" class="link" mat-button
     routerLinkActive="active">
    <ng-container *ngIf="project.parentId else topProject">
      {{ project.nameWithoutParents }}
    </ng-container>
    <ng-template #topProject>
      <span class="accounting-id">{{ project.accountingId }}</span>
      {{ project.nameWithoutParents }}
    </ng-template>

    <app-project-alert-icon [alerts]="alerts" [project]="project"></app-project-alert-icon>
  </a>

  <app-project-deadline *ngIf="showDeadline" [deadline]="project.details.deadline"></app-project-deadline>

  <ng-container *ngIf="editable">
    <button [matMenuTriggerFor]="additionalActionsMenu" class="small-icon-button" mat-icon-button
            matSuffix
            matTooltip="Additional actions for this project group. You can also right-click to see the same menu">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #additionalActionsMenu="matMenu" class="wider-menu">
      <ng-container
        *ngTemplateOutlet="contextMenuContent; context:{action: menuCloseCallback()}"></ng-container>
    </mat-menu>
  </ng-container>

  <ng-template #contextMenu>
    <div cdkMenu
         class="mat-mdc-menu-panel mat-mdc-menu-content mat-elevation-z8 wider-menu">
      <ng-container *ngTemplateOutlet="contextMenuContent"></ng-container>
    </div>
  </ng-template>

  <ng-template #contextMenuContent let-action="action">
    <mat-button-toggle-group aria-label="Project status">
      @for (s of statuses; track s) {
        <mat-button-toggle [value]="s" [checked]="project.status == s" (change)="changeStatus(project, s, action);">
          <app-project-status [status]="s" [condense]="true"></app-project-status>
          {{ s | readable }}
        </mat-button-toggle>
      }
    </mat-button-toggle-group>
    <button (click)="editProject(project)" cdkMenuItem class="edit-button" mat-menu-item>
      <mat-icon>visibility</mat-icon>
      Details
    </button>
    <button (click)="addChild(project)" cdkMenuItem class="add-child-button" mat-menu-item>
      <mat-icon>add</mat-icon>
      Add child
    </button>
    <button (click)="editPermissions(project)" cdkMenuItem class="add-child-button" mat-menu-item>
      <mat-icon>supervisor_account</mat-icon>
      Permissions
    </button>
  </ng-template>
</div>
