import {computed, effect, Injectable, Signal} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable} from "rxjs";
import {AlertDetailed, AlertGrouped, AlertSnoozeRequest, AlertStatus} from "./model/alert";
import {WebSocketService} from "./ws.service";
import {AuthService} from "./auth.service";
import {UserInfo} from "./model/user";
import {map} from "rxjs/operators";
import {toSignal} from "@angular/core/rxjs-interop";
import {environment} from "../../environments/environment";

@Injectable()
export class AlertService {
  public alerts$: BehaviorSubject<AlertDetailed[]> = new BehaviorSubject([] as AlertDetailed[]);
  public alertsGroupedByEquipment$: Observable<AlertGrouped[]> = this.alerts$.pipe(map(alerts => {
    const grouped: AlertGrouped[] = [];
    alerts
      .filter(a => a.status === AlertStatus.ACTIVE && a.snoozedUntil === null)
      .forEach(alert => this.groupAlert(grouped, alert));
    return grouped;
  }));
  public snoozedAlertsGroupedByEquipment$: Observable<AlertGrouped[]> = this.alerts$.pipe(map(alerts => {
    const grouped: AlertGrouped[] = [];
    alerts
      .filter(a => a.status === AlertStatus.ACTIVE && a.snoozedUntil !== null)
      .forEach(alert => this.groupAlert(grouped, alert));
    return grouped;
  }));
  public alertsSignal: Signal<AlertDetailed[]> = toSignal(this.alerts$, {initialValue: []})
  public alertsGroupedByEquipmentSignal: Signal<AlertGrouped[]> = toSignal(this.alertsGroupedByEquipment$, {initialValue: []})
  public snoozedAlertsGroupedByEquipmentSignal: Signal<AlertGrouped[]> = toSignal(this.snoozedAlertsGroupedByEquipment$, {initialValue: []})
  public allAlertsGroupedByEquipmentSignal: Signal<AlertGrouped[]> = computed(() => [...this.alertsGroupedByEquipmentSignal(), ...this.snoozedAlertsGroupedByEquipmentSignal()]);
  private alertSnapshot: AlertDetailed[] = [];

  constructor(private http: HttpClient,
              private authService: AuthService,
              private ws: WebSocketService,
  ) {
    effect(() => {
      const loggedIn = authService.isLoggedInSignal()?.value || false;
      if (loggedIn) {
        this.authService.getCurrentUser().subscribe(user => {
          if (user) {
            console.log("AlertService subscribing to alerts", user);
            ws.alertMessages$.subscribe(alertMsg => {
              alertMsg.forEach(alert => this.processNewAlertMessage(user, alert));
              this.alerts$.next([...this.alertSnapshot]);
            });
          }
        });
      }
    });
  }

  getAlerts(): BehaviorSubject<AlertDetailed[]> {
    // this.http.get<Alert[]>(environment.apiUrl + "/alert")
    return this.alerts$;
  }

  snooze(request: AlertSnoozeRequest): Observable<any> {
    return this.http.post<AlertDetailed[]>(environment.apiUrl + "/alert/snooze", request)
  }

  private processNewAlertMessage(user: UserInfo, alert: AlertDetailed) {
    if (alert.users.includes(user.email) && alert.status === AlertStatus.ACTIVE) {
      // replace if exists
      let replaced = false;
      this.alertSnapshot.forEach((a, i) => {
        if (a.id === alert.id) {
          replaced = true;
          this.alertSnapshot[i] = alert;
        }
      });
      if (!replaced) {
        this.alertSnapshot.push(alert);
      }
    } else {
      // if user no longer exists in users array, remove from their alerts
      this.alertSnapshot = this.alertSnapshot.filter(a => a.id != alert.id);
    }
  }

  private groupAlert(grouped: AlertGrouped[], alert: AlertDetailed) {
    const e = grouped
      .find(a => a.project.id === alert.projectId && a.type === alert.type);
    if (e) {
      e.culpritEquipmentIds.push(alert.equipmentId);
      e.alertIds.push(alert.id);
      e.snoozedUntil = e.snoozedUntil || alert.snoozedUntil;
    } else {
      grouped.push({
        severity: alert.severity,
        type: alert.type,
        message: alert.message,
        project: alert.project,
        culpritEquipmentIds: [alert.equipmentId],
        alertIds: [alert.id],
        snoozedUntil: alert.snoozedUntil,
      });
    }
  }
}
