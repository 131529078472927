import {ChangeDetectorRef, Component} from '@angular/core';
import {UserShortResponse} from "../../api/model/user";
import {AuthService} from "../../api/auth.service";
import {MatDialog} from "@angular/material/dialog";
import {UserAddEditDialogComponent, UserAddEditDialogData} from "./add-edit-dialog/user-add-edit-dialog.component";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.sass']
})
export class UsersComponent {
  displayedColumns: string[] = ['name', 'email', 'roles', 'actions'];
  users: UserShortResponse[] = [];

  constructor(
    public dialog: MatDialog,
    private _changeDetectorRef: ChangeDetectorRef,
    private authService: AuthService,
  ) {
    this.loadUsers();
  }

  openInviteUserDialog() {
    const dialogData: UserAddEditDialogData = {
      user: undefined,
    }
    const dialogRef = this.dialog.open(UserAddEditDialogComponent, {data: dialogData, width: "600px"});

    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.loadUsers();
    });
  }

  edit(user: UserShortResponse) {
    const dialogData: UserAddEditDialogData = {
      user: user,
    }
    const dialogRef = this.dialog.open(UserAddEditDialogComponent, {data: dialogData, width: "600px"});

    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.loadUsers();
    });
  }

  delete(user: UserShortResponse) {
    alert("under construction 🚧");
  }

  private loadUsers() {
    this.authService.getAllUsers(true).subscribe(users => {
      this.users = users;
      this._changeDetectorRef.markForCheck();
    })
  }
}
