.sidenav-container {

  .sidenav {
    padding: 8px;
    transition: visibility 300ms cubic-bezier(.02, .68, .63, .98);

    .footer {
      position: fixed;
      bottom: 0;
      opacity: .3;

      &:hover {
        opacity: 1
      }
    }
  }

  .sidenav-content {
    padding: 0 8px;

    .toggle-button-container {
      position: sticky;
      top: 0;
      z-index: 101;

      .toggle-button {
        position: absolute;
        top: 4px
      }
    }
  }
}
