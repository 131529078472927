.global-search
  width: 360px

  .mat-mdc-text-field-wrapper
    background: #4a4a4a99

  .mat-mdc-form-field-focus-overlay
    background-color: transparent

.global-search-autocomplete
  max-height: 500px !important
