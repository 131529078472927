.equipment-add-edit-dialog-title
  display: flex

  .title-text
    flex: 1
    text-overflow: ellipsis
    overflow: hidden
    white-space: nowrap

.equipment-add-edit-dialog-content:has(.serial-numbers)
  max-height: calc(100vh - 58px - 53px)

.add-edit-equipment-form
  display: flex
  flex-direction: row
  flex-wrap: wrap

  mat-divider
    margin: 0 12px

  mat-form-field, .field-container
    margin: 8px 0

  mat-form-field.sku, mat-form-field.manufacturer
    width: 160px

  mat-form-field.model, mat-form-field.product-description, mat-form-field.hyperlink, .alerts, mat-form-field.notes, .comments
    width: calc(160px * 2 + 8px)

  mat-form-field.product-description, mat-form-field.notes
    font-size: 12px

  mat-form-field.project-code, mat-form-field.quantity
    width: 138px

  mat-form-field.status, mat-form-field.diff-from-contract
    width: 284px

  mat-form-field.estimated-ship-by-date,
  mat-form-field.received-on,
  mat-form-field.purchase-order-date,
  mat-form-field.sales-order-confirmation-date,
  mat-form-field.invoice-date,
  mat-form-field.installed-date,
  mat-form-field.warranty-date
    width: 212px

  .column .row mat-form-field:not(:first-child)
    margin-left: 8px

  .main-info .mdc-text-field--disabled .mdc-text-field__input
    color: #ffffff88

  .diff-from-contract-row
    display: flex
    align-items: center

  .column.item-column
    flex: 1
    min-width: 542px

    .item-container
      display: flex
      flex-direction: column
      gap: 16px 0

      .item-form-group
        display: flex
        align-items: center
        flex-wrap: wrap
        gap: 0 8px

    mat-form-field.serial-numbers
      width: 200px

    mat-form-field.firmware-version
      width: 128px

    mat-button-toggle-group.statuses
      flex-wrap: wrap

  .splitter
    display: flex
    flex-direction: row
    align-items: center
    justify-content: flex-end

    label
      margin-right: 8px

.equipment-add-edit-dialog-actions
  .api-error
    margin-left: 12px

  button.delete
    position: absolute
    right: 0
    color: #666666

    &:hover
      color: #ff2727


.equipment-catalog-autocomplete
  .mat-mdc-option
    font-size: 14px
    min-height: 0

    .mdc-list-item__primary-text
      display: flex
      align-items: center

    .manufacturer, .sku, .model
      display: inline-block

    .manufacturer
      width: 120px

    .sku
      width: 160px

    .model
      width: 360px

  .exact
    font-weight: bold
