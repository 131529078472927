.wider-menu
  max-width: fit-content

.project-list-item
  border-left: 2px solid #666
  padding: 4px 8px
  margin-left: 4px
  display: flex
  align-items: center
  min-width: 0
  gap: 2px

  &:has(.active)
    border-left-color: #4caf50
    background: #4caf5022

  &.ACTIVE ~ .ACTIVE,
  &.PENDING ~ .PENDING,
  &.COMPLETE ~ .COMPLETE
    margin-top: 0

  a.mat-mdc-button
    height: auto
    letter-spacing: 0

  a.link
    flex: 1 0 0
    justify-content: left
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    display: block
    padding: 0

    .accounting-id
      min-width: 36px
      padding-right: 4px
      display: inline-block
