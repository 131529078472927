<div class="project-list-filters">
  <mat-form-field appearance="outline" class="no-bottom filter dense-minimum">
    <mat-label>Filter</mat-label>
    <input #filter [(ngModel)]="filterValue" matInput type="text">
    <button (click)="clearFilter()" aria-label="Clear" mat-icon-button matSuffix>
      @if (filterLoading) {
        <mat-spinner class="progress-spinner" diameter="24" matSuffix></mat-spinner>
      } @else {
        <mat-icon [class.empty]="isFilterEmpty()">close</mat-icon>
      }
    </button>
  </mat-form-field>

  <mat-button-toggle-group (change)="onFilterChange()" [(ngModel)]="filterStatuses"
                           aria-label="Statuses" multiple name="status">
    <mat-button-toggle disabled>Status</mat-button-toggle>
    <mat-button-toggle *ngFor="let s of statuses" [value]="s">{{ s }}</mat-button-toggle>
  </mat-button-toggle-group>

  <mat-form-field appearance="outline" class="no-bottom filter-by-alert dense-minimum">
    <mat-label>Alert</mat-label>
    <mat-select (selectionChange)="onFilterChange()" [(value)]="filterWithAlerts">
      <mat-option value="false"></mat-option>
      <mat-option value="true">Show only with</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" class="no-bottom ordering dense-minimum">
    <mat-label>Order by</mat-label>
    <mat-select (selectionChange)="onOrderChange()" [(value)]="order">
      <mat-option value="accountingId-asc">Accounting Id ↑</mat-option>
      <mat-option value="accountingId-desc">Accounting Id ↓</mat-option>
      <mat-option value="name-asc">Name ↑</mat-option>
      <mat-option value="name-desc">Name ↓</mat-option>
      <mat-option value="deadline-asc">Deadline ↑</mat-option>
      <mat-option value="deadline-desc">Deadline ↓</mat-option>
    </mat-select>
  </mat-form-field>

  <button (click)="openDialog()" class="add-project" color="primary" extended mat-fab>
    <mat-icon>add</mat-icon>
    Add project
  </button>
</div>

<mat-card class="card column-1">
  <mat-card-content class="project-list-view-card-content">
    <div class="project-list-wrapper">
      @for (group of projectGroups; track group.accountingId; let last = $last) {
        <app-project-list [expanded]="expanded" [projects]="group.projects"></app-project-list>
        @if (!last) {
          <mat-divider></mat-divider>
        }
      }
    </div>
  </mat-card-content>
</mat-card>
