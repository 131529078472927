import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  effect,
  ElementRef,
  OnChanges,
  OnInit,
  Signal,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {LiveAnnouncer} from "@angular/cdk/a11y";
import {MatSort, Sort} from "@angular/material/sort";
import {
  DiffFromContract,
  Equipment,
  EquipmentGroup,
  EquipmentStatus,
  isFinal,
  isReceived,
  Item,
  ItemStatus
} from "../../api/model/equipment";
import {EquipmentService} from "../../api/equipment.service";
import {SelectionModel} from "@angular/cdk/collections";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {
  EquipmentAddEditDialogComponent,
  EquipmentAddEditDialogData
} from "../add-edit-dialog/equipment-add-edit-dialog.component";
import {Project} from "../../api/model/project";
import moment, {Moment} from "moment/moment";
import {unitOfTime} from "moment";
import {BulkEditDialogComponent, BulkEditDialogData} from "../bulk-edit-dialog/bulk-edit-dialog.component";
import {AlertService} from "../../api/alert.service";
import {AlertDetailed} from "../../api/model/alert";
import {ActivatedRoute, Router} from "@angular/router";
import {
  MoveCopyAssignDialogAction,
  MoveCopyAssignDialogComponent,
  MoveCopyAssignDialogData
} from "../move-copy-assign-dialog/move-copy-assign-dialog.component";
import {projectUrl} from "../../project/url.pipe";
import {MatSnackBar} from "@angular/material/snack-bar";
import {fromEvent, Observable} from "rxjs";
import {debounceTime} from "rxjs/operators";
import {CheckBoxFilter, CheckBoxOption, option} from "../check-box-filter/check-box-filter.component";
import * as _ from "lodash";
import {MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions} from "@angular/material/checkbox";
import {getCurrentPathname, populateChildren} from "../../util";
import {ComponentCanDeactivate} from "../../pending-changes.guard";
import {makeReadable} from "../../readable.pipe";
import {CookieService} from "ngx-cookie-service";

@Component({
  selector: 'app-equipment-table',
  templateUrl: './equipment-table.component.html',
  styleUrls: ['./equipment-table.component.sass'],
  providers: [
    {provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: {clickAction: 'noop'} as MatCheckboxDefaultOptions},
  ],
})
export class EquipmentTableComponent implements OnInit, AfterViewInit, OnChanges, ComponentCanDeactivate {
  projects!: Project[];
  groups: ProjectHeader[] | undefined;

  defaultColumns: Column[] = [
    {key: "actions", label: "Selection, icons", visible: true},
    {key: "alerts", label: "Alerts", visible: true},
    {key: "notes", label: "Notes", visible: false},
    {key: "comments", label: "Comments", visible: false},
    {key: "project", label: "Project", visible: true},
    {key: "projectCode", label: "Project Code", visible: true},
    {key: "manufacturer", label: "Manufacturer", visible: true},
    {key: "sku", label: "SKU", visible: true},
    {key: "model", label: "Model", visible: true},
    {key: "quantity", label: "Quantity", visible: true},
    {key: "responsible", label: "Responsible", visible: true},
    {key: "status", label: "Status", visible: true},
    {key: "estimatedShipByDate", label: "Estimated ship by date", visible: true},
    {key: "receivedOnDate", label: "Received on date", visible: true},
    {key: "diffFromContract", label: "Differs from original contract (DFC)", visible: true},
    {key: "inStock", label: "In Stock", visible: true},
    {key: "pdiDone", label: "PDI", visible: true},
    {key: "readyToShip", label: "Ready To Ship", visible: true},
    {key: "onClientPremises", label: "On Client Premises", visible: true},
    {key: "installed", label: "Installed", visible: true},
    {key: "serialNumbers", label: "Serial numbers", visible: false},
    {key: "timeline", label: "Daily/Weekly/Monthly Timeline", visible: false},
  ] as Column[];
  columns: Column[] = [];
  diffFromContractEnum: typeof DiffFromContract = DiffFromContract;
  dataSource = new MatTableDataSource<Equipment | ProjectHeader>();
  selectionFromQueryParams: string[] | undefined;
  alertTypeFromQueryParams: string | undefined;
  selection = new SelectionModel<Equipment | ProjectHeader>(true, []);
  arrayOf12: number[] = [...Array(12).keys()]
  today: Moment = moment();
  levelEnum: typeof Level = Level;
  level: Level = Level.DAY;
  target: Moment = moment();
  alerts: Signal<AlertDetailed[]> = this.alertService.alertsSignal;
  context: Context | undefined;
  contextEnum: typeof Context = Context;
  @ViewChild(MatSort) sort!: MatSort;
  filterLoading: boolean = true;
  filterValue: string = "";
  loadedByFilter: string = "";
  @ViewChild("filter") filterField!: ElementRef;
  statuses: EquipmentStatus[] = Object.values(EquipmentStatus);
  projectIds!: string[];
  equipmentGroup: EquipmentGroup = EquipmentGroup.PROJECT;
  protected readonly EquipmentStatus = EquipmentStatus;
  protected readonly ItemStatus = ItemStatus;
  private itemStatusFilterOptions = [
    {value: ItemStatusFilter.NOT_STARTED, display: "Not started"},
    {value: ItemStatusFilter.IN_PROGRESS, display: "In progress"},
    {value: ItemStatusFilter.COMPLETE, display: "Complete"},
  ];
  advancedFilter: AdvancedFilters = {
    alerts: {
      key: "alerts", display: "Alerts", remember: false,
      options: [], selected: []
    },
    selection: {
      key: "selection", display: "Selection", remember: false,
      options: [
        {display: "Hide non-selected", value: HasFilter.HAS},
        {display: "Hide selected", value: HasFilter.DOESNT_HAVE}
      ], selected: []
    },
    notes: {
      key: "notes", display: "Notes", remember: true,
      options: [
        {display: "Has notes", value: HasFilter.HAS},
        {display: "Doesn't have notes", value: HasFilter.DOESNT_HAVE}
      ], selected: []
    },
    comments: {
      key: "comments", display: "Comments", remember: true,
      options: [
        {display: "Has comments", value: HasFilter.HAS},
        {display: "Doesn't have comments", value: HasFilter.DOESNT_HAVE}
      ], selected: []
    },
    project: {
      key: "project", display: "Project", remember: false,
      options: [], selected: []
    },
    manufacturer: {
      key: "manufacturer", display: "Manufacturer", remember: true,
      options: [], selected: []
    },
    responsible: {
      key: "responsible", display: "Responsible", remember: true,
      options: [], selected: []
    },
    status: {
      key: "status", display: "Status", remember: true,
      options: Object.values(EquipmentStatus).map(s => option(s)), selected: []
    },
    diffFromContract: {
      key: "diffFromContract", display: "Differs From Contract", remember: true,
      options: [
        {display: "Does not differ", value: ""},
        {display: "Yes, needs action", value: DiffFromContract.YES_NEEDS_ACTION},
        {display: "Yes, resolved", value: DiffFromContract.YES_RESOLVED}
      ], selected: []
    },
    inStock: {
      key: "inStock", display: "In Stock", remember: true,
      options: this.itemStatusFilterOptions, selected: []
    },
    pdiDone: {
      key: "pdiDone", display: "PDI Done", remember: true,
      options: this.itemStatusFilterOptions, selected: []
    },
    readyToShip: {
      key: "readyToShip", display: "Ready To Ship", remember: true,
      options: this.itemStatusFilterOptions, selected: []
    },
    onClientPremises: {
      key: "onClientPremises", display: "On Client Premises", remember: true,
      options: this.itemStatusFilterOptions, selected: []
    },
    installed: {
      key: "installed", display: "Istalled", remember: true,
      options: this.itemStatusFilterOptions, selected: []
    },
  }
  private firstLoad: boolean = true;
  private dialogRef: MatDialogRef<any> | undefined;
  private lastSelected: Equipment | undefined;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private alertService: AlertService,
    private equipmentService: EquipmentService,
    private cookieService: CookieService,
    private _snackBar: MatSnackBar,
    private _liveAnnouncer: LiveAnnouncer,
    private _changeDetectorRef: ChangeDetectorRef,
  ) {
    const persistedColumns = JSON.parse(localStorage.getItem(`equipmentTableColumns`) || JSON.stringify(this.defaultColumns)) as Column[];
    this.columns = _.cloneDeep(this.defaultColumns);
    this.columns.forEach(c => {
      const persistedVis = persistedColumns.find(pc => pc && pc.key == c.key);
      if (persistedVis !== undefined) c.visible = persistedVis.visible;
    });
    effect(() => {
      const alertTypeSet = [...new Set(this.alerts().map(a => a.type))];

      this.advancedFilter.alerts.options = alertTypeSet.map(alertType => {
        // TODO filter out only alerts in currently shown project/-s (now shows all for user)
        return {display: makeReadable(alertType), value: alertType};
      });

      console.log("EquipmentTableComponent alerts loaded", this.alerts());
    });
  }

  get selectedFilters(): CheckBoxFilter[] {
    return Object.values(this.advancedFilter).map(f => f as CheckBoxFilter).filter(f => f.selected.length > 0);
  }

  get displayedColumns(): string[] {
    return this.columns.filter(c => c.visible && !c.hidden).map(c => c.key);
  }

  get project(): Project | undefined {
    if (this.context === Context.SINGLE_PROJECT) {
      return this.projects.find(p => p.id === this.projectIds[0]);
    }
    return undefined;
  }

  get selected(): Equipment[] {
    return this.selection.selected as Equipment[];
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.selectionFromQueryParams = Array.isArray(params["eq"]) ? params["eq"] : (params["eq"] ? [params["eq"]] : []);
      this.alertTypeFromQueryParams = params["alert"];
      console.log("equipmentTable init initialSelection", this.selectionFromQueryParams, this.alertTypeFromQueryParams);
      this.updateSelectionFromQueryParams(true);
      const query = params["q"];
      if (query && query.length > 0) {
        this.clearFilter(false);
        this.filterValue = query;
        this.filterField.nativeElement.dispatchEvent(new KeyboardEvent('keyup'));
      }
    });
    this.route.data.subscribe(data => {
      const parentRouteProjects = this.route.parent?.snapshot?.data['projects'] as Project[];
      if (data['project']) {
        this.context = Context.SINGLE_PROJECT;
        this.projectIds = [data['project'].id];
        this.projects = [data['project']];
        this.clearFilter(false);
        this.advancedFilter.alerts.selected = this.alertTypeFromQueryParams ? [this.alertTypeFromQueryParams] : [];
        this.advancedFilter.selection.selected = this.selectionFromQueryParams || [];
      } else if (data['rma']) {
        this.context = Context.SERVICE_SPARE_PARTS;
        this.columns.find(c => c.key == "projectCode")!.hidden = true;
        this.projectIds = [data['rma'].id];
        this.projects = [];
        this.equipmentGroup = EquipmentGroup.SERVICE;
      } else if (parentRouteProjects) {
        this.context = Context.ACCOUNTING_ID;
        this.projectIds = parentRouteProjects.map(p => p.id);
        this.projects = parentRouteProjects;
        if (parentRouteProjects.length == 1) {
          this.router.navigateByUrl(projectUrl(parentRouteProjects[0]));
        }
        this.clearFilter(false);
      } else {
        this.context = Context.ALL_EQUIPMENT;
        this.columns.find(c => c.key == "timeline")!.hidden = true;
        this.projectIds = [];
      }
      console.log("equipmentTable init route data parsed", this.context, this.projectIds);

      if (this.context !== Context.SERVICE_SPARE_PARTS) {
        this.restoreFilterSelection();
      }

      this.loadEquipment(true);
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;

    const keyUpEvent = fromEvent(this.filterField.nativeElement, 'keyup')
    keyUpEvent.subscribe(_ => {
      if (this.filterValue != this.loadedByFilter) {
        this.filterLoading = true;
      }
    })
    keyUpEvent
      .pipe(debounceTime(500)).subscribe(c => {
      if (this.filterValue != this.loadedByFilter) {
        this.loadEquipment()
      }
    });
  }

  openAddDialog(projectId: string): void {
    this.openEditDialog(undefined, projectId);
  }

  openEditDialog(equipment: Equipment | undefined, projectId: string = equipment?.projectId || ""): void {
    const project = this.projects.find(p => p.id == projectId) || {id: projectId} as Project;
    const dialogData: EquipmentAddEditDialogData = {
      project: project,
      equipment: equipment,
      group: this.equipmentGroup
    }
    const dialogRef = this.dialog.open(EquipmentAddEditDialogComponent,
      {maxWidth: "97vw", data: dialogData, closeOnNavigation: false});
    this.dialogRef = dialogRef;
    console.log("EquipmentTableComponent dialog being opened dialogRef", this.dialogRef);
    dialogRef.afterClosed().subscribe(result => {
      console.log("EquipmentTableComponent afterClosed result", result);
      this.dialogRef = undefined;
      if (result)
        this.loadEquipment(false);
    });
  }

  openEditDialogBySelection(): void {
    if (this.selection.selected.length == 1) {
      const singleSelection = this.selection.selected[0];
      if (!(singleSelection instanceof ProjectHeader))
        this.openEditDialog(singleSelection);
    } else if (this.selection.selected.length > 1) {
      this.openEditMultipleDialog()
    }
  }

  assignedTooltip(equipment: Equipment): string {
    if (equipment.assignedProjectId != null) {
      const originProject = this.projects.find(p => p.id === equipment.projectId);
      const assignedProject = this.projects.find(p => p.id === equipment.assignedProjectId);
      return `Assigned from ${originProject?.nameWithoutParents} to ${assignedProject?.nameWithoutParents}`
    } else {
      return "";
    }
  }

  openAssignDialogBySelection(): void {
    this.openMoveCopyAssignDialog(MoveCopyAssignDialogAction.ASSIGN);
  }

  selectedOnlyAssigned(): boolean {
    return this.selected.length > 0 && this.selected.every(e => e.assignedProjectId != null);
  }

  unassignSelection(): void {
    if (this.selectedOnlyAssigned()) {
      const target = this.selected.filter(e => e.assignedProjectId != null);
      this.equipmentService.assign({
        ids: target.map(e => e.id),
        projectId: null,
      }).subscribe(res => {
        this.loadEquipment()
      });
    }
  }

  openMoveToDialogBySelection(): void {
    this.openMoveCopyAssignDialog(MoveCopyAssignDialogAction.MOVE);
  }

  openCopyDialogBySelection(): void {
    this.openMoveCopyAssignDialog(MoveCopyAssignDialogAction.COPY);
  }

  openMoveCopyAssignDialog(type: MoveCopyAssignDialogAction) {
    const dialogData: MoveCopyAssignDialogData = {
      projects: this.projects,
      equipment: this.selected,
      action: type,
    }
    const dialogRef = this.dialog.open(MoveCopyAssignDialogComponent, {data: dialogData});
    this.dialogRef = dialogRef;
    dialogRef.afterClosed().subscribe(result => {
      this.dialogRef = undefined;
      if (result)
        this.ngOnInit()
    });
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.active} ${sortState.direction}`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  clearFilter(andLoad: boolean = true, key: string | undefined = undefined) {
    if (key) {
      this.advancedFilter[key].selected = [];
    } else {
      this.filterValue = '';
      this.advancedFilter.alerts.selected = [];
      this.advancedFilter.selection.selected = [];
      this.advancedFilter.project.selected = [];
      this.advancedFilter.manufacturer.selected = [];
      this.advancedFilter.responsible.selected = [];
      this.advancedFilter.status.selected = [];
      this.advancedFilter.diffFromContract.selected = [];
      this.advancedFilter.inStock.selected = [];
      this.advancedFilter.pdiDone.selected = [];
      this.advancedFilter.readyToShip.selected = [];
      this.advancedFilter.onClientPremises.selected = [];
      this.advancedFilter.installed.selected = [];
    }
    if (andLoad) {
      this.rememberFilterSelection();
      this.loadEquipment();
    }
  }

  isFilterEmpty(): boolean {
    return this.loadedByFilter.length == 0
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.selection.selected.length == 0) {
      this.selection.select(...this.dataSource.filteredData.filter(d => !(d instanceof ProjectHeader)));
    } else {
      this.selection.clear();
    }
  }

  checkboxLabel(row?: Equipment): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.projectCode}`;
  }

  statusClass(e: Equipment): string {
    const received = (isReceived(e.status)) ? " received" : "";
    const final = (isFinal(e.status)) ? " final" : "";

    return this.timelineUnitClass(5, e, this.today) + received + final + " status-" + e.status;
  }

  jumpToToday() {
    this.target = this.today.clone();
  }

  goUp() {
    if (Level[this.level - 1] != undefined)
      this.level = this.level - 1;
  }

  goDown(i: number) {
    if (Level[this.level + 1] != undefined) {
      this.target.add(i - 5, this.levelToMomentUnit(this.level));
      this.level = this.level + 1;
    }
  }

  goLeft() {
    this.target.subtract(1, this.levelToMomentUnit(this.level))
  }

  goRight() {
    this.target.add(1, this.levelToMomentUnit(this.level))
  }

  timelineHeadline(): string {
    const fromYear = this.unitToMoment(0, this.target).format("YYYY")
    const toYear = this.unitToMoment(11, this.target).format("YYYY")
    const toString = fromYear == toYear ? "" : "-" + toYear;
    return this.levelToMomentUnit(this.level) + " " + fromYear + toString;
  }

  timelineTitle(i: number): string {
    return this.unitToMoment(i, this.target).format(this.levelToMomentFormat(this.level))
  }

  timelineCell(i: number, element: Equipment): string {
    return "";
  }

  timelineTooltip(i: number, e: Equipment | undefined = undefined): string {
    const headerDate = !e ? this.tooltipHeaderDate(i) : "";
    return this.timelineUnitClass(i, e).trim() + " " + headerDate;
  }

  timelineUnitClass(i: number, e: Equipment | undefined = undefined, target: Moment = this.target): string {
    const projectDeadlines = this.projects
      .filter(p => !e || e.projectId == p.id)
      .map(p => moment(p.details.deadline))

    moment(this.projects.find(p => p.id == e?.projectId)?.details.deadline);
    const today = this.isToday(i, target);
    const deadline = projectDeadlines.find(deadline => this.isSame(i, deadline, target));
    let sameOrBeforeToday = this.isSameOrBefore(i, moment(this.today), target);
    const pendingOrder = e && (e.status == EquipmentStatus.PENDING_ORDER || e.status == EquipmentStatus.PENDING_INFO) &&
      this.isSameOrAfter(i, moment(e.created.at), target) && sameOrBeforeToday;
    let onTheWayStart = e && this.isSameOrAfter(i, moment(e.purchaseOrderDate || e.salesOrderConfirmationDate || e.created.at), target);
    const onTheWay = e && onTheWayStart &&
      this.isSameOrBefore(i, moment(e.receivedOnDate || e.estimatedShipByDate), target);
    const received = e && onTheWayStart && this.isSameOrBefore(i, moment(e.receivedOnDate), target) && isReceived(e.status);
    const final = e && onTheWayStart && this.isSameOrBefore(i, moment(e.receivedOnDate), target) && isFinal(e.status);
    const overdue = onTheWayStart && sameOrBeforeToday &&
      !e?.receivedOnDate && e?.estimatedShipByDate && moment(e.estimatedShipByDate).isSameOrBefore(this.today);
    const weekend = this.level == Level.DAY && this.unitToMoment(i, target).isoWeekday() >= 6;
    return (today ? " today" : "") + (deadline ? " deadline" : "") + (pendingOrder ? " pending-order" : "")
      + (onTheWay ? " on-the-way" : "") + (received ? " received" : "") + (final ? " final" : "") + (overdue ? " overdue" : "")
      + (weekend ? " weekend" : "");
  }

  tooltipComment(equipment: Equipment): string {
    return `Has ${equipment.commentIds.length} comment${equipment.commentIds.length > 1 ? "s" : ""}`;
  }

  toggleColumnVisibility(c: Column) {
    c.visible = !c.visible;
    console.log("EquipmentTableComponent toggleColumnVisibility", c);
    this.persistColumns();
  }

  resetColumnVisibility() {
    this.columns = this.defaultColumns;
    this.persistColumns();
  }

  persistColumns() {
    localStorage.setItem(`equipmentTableColumns`, JSON.stringify(
      this.columns.map(value => {
        const cloned = _.clone(value);
        cloned.hidden = undefined;
        return cloned;
      })
    ));
  }

  isGroup(index: any, item: any): boolean {
    return item.projectHeader;
  }

  isNotGroup(index: any, item: any): boolean {
    return !item.projectHeader;
  }

  isGroupVisible(equipment: Equipment): boolean {
    if (this.groups) {
      return this.groups.find(g => g.project.id == equipment.projectId)?.expanded || false;
    } else return true;
  }

  areAllGroupsCollapsed(): boolean {
    return !this.groups?.find(group => group.expanded);
  }

  expandAllGroups() {
    this.groups?.forEach(group => this.expandCollapseGroup(group, true));
  }

  collapseAllGroups() {
    this.groups?.forEach(group => this.expandCollapseGroup(group, false));
  }

  toggleGroup(group: ProjectHeader): void {
    this.expandCollapseGroup(group, !group.expanded);
    console.log("equipment toggleGroup", this.groups, this.dataSource.data);
  }

  copyToClipboard(selected: (Equipment | ProjectHeader)[]) {
    const content = selected.filter(t => !(t instanceof ProjectHeader))
      .map(e => {
        const eq = (e as Equipment);
        return `${eq.sku}\t${eq.model}\t${eq.quantity}`;
      })
      .join('\n');

    navigator.clipboard.writeText(content)
      .then(value => {
        console.log("EquipmentTableComponent copyToClipboard", selected, content);
        this._snackBar.open(`Copied ${selected.length} items to clipboard!`,
          undefined, {duration: 5000});
      }).catch(reason => {
      console.error("EquipmentTableComponent copyToClipboard", reason, content);
      this._snackBar.open('Could not copy to clipboard... ' + reason,
        undefined, {duration: 5000});
    });
  }

  advancedFilterChanged(filter: CheckBoxFilter, selection: string[]) {
    filter.selected = selection;
    this.rememberFilterSelection();
    this.loadEquipment();
  }

  advancedFilterBySelectionChanged(filter: CheckBoxFilter, selection: string[]) {
    if (selection.length === 0) {
      filter.selected = [];
    } else {
      const selectedIds = this.selected.map(e => e.id)
      const existingSelection = filter.selected.filter(i => i != HasFilter.HAS && i != HasFilter.DOESNT_HAVE);
      if (selection.includes(HasFilter.DOESNT_HAVE)) {
        filter.selected = _.uniq(selectedIds.concat(existingSelection));
      }
      if (selection.includes(HasFilter.HAS)) {
        filter.selected = _.uniq(this.dataSource.data
          .filter(e => !(e instanceof ProjectHeader))
          .map(e => (e as Equipment).id)
          .filter(id => !selectedIds.includes(id))
          .concat(existingSelection)
        );
      }
      this.selection.clear();
    }
    this.loadEquipment();
  }

  trackBy(index: number, item: Equipment | ProjectHeader): string {
    return (item instanceof ProjectHeader) ? item.project.id : item.id;
  }

  itemStatusClass(status: ItemStatus, equipment: Equipment): string {
    const statusCount = this.statusCount(status, equipment);
    return (statusCount === 0 ? "" : (statusCount === equipment.quantity ? "status-INSTALLED" : "status-IN_STOCK"));
  }

  canDeactivate(): boolean | Observable<boolean> {
    console.log("EquipmentTableComponent canDeactivate guard activated, dialogRef", this.dialogRef, !this.dialogRef);
    return !this.dialogRef;
  }

  unassignButtonTooltip(): string {
    return "Unassign" + (this.selectedOnlyAssigned() ? '' : '. Must select only assigned items');
  }

  lookupDisplayString(filter: CheckBoxFilter, option: string) {
    return filter.options.find(o => o.value == option)?.display ?? "";
  }

  toggleRowSelection(equipment: Equipment, $event: PointerEvent) {
    this.selection.toggle(equipment);
    if ($event.shiftKey) {
      const isSelected = this.selection.isSelected(equipment)
      if (this.lastSelected) {
        const data = this.dataSource.data;
        const lastSelectedIndex = data.indexOf(this.lastSelected);
        const clickedIndex = data.indexOf(equipment);
        const range = data.slice(Math.min(lastSelectedIndex, clickedIndex), Math.max(clickedIndex, lastSelectedIndex) + 1)
          .filter(v => !(v instanceof ProjectHeader));
        if (isSelected) {
          this.selection.select(...range);
        } else {
          this.selection.deselect(...range)
        }
        console.log("EquipmentTableComponent toggleRowSelection detailed debug", data, lastSelectedIndex, clickedIndex, range);
        this.cookieService.set("reminderMultiSelect", "tried", {expires: 90})
      }
    } else {
      if ($event.pointerType == "mouse" && !this.cookieService.check("reminderMultiSelect")) {
        this.cookieService.set("reminderMultiSelect", "reminded", {expires: 1})
        this._snackBar.open(`Try multi-select by holding shift and clicking the checkboxes with your mouse`,
          undefined, {duration: 5000});
      }
    }
    this.lastSelected = equipment;
  }

  protected isSame(i: number, date: Moment, target: Moment) {
    return this.unitToMoment(i, target)
      .isSame(date, this.levelToMomentUnit(this.level));
  }

  protected isSameOrBefore(i: number, date: Moment, target: Moment) {
    return this.unitToMoment(i, target)
      .isSameOrBefore(date, this.levelToMomentUnit(this.level));
  }

  protected isSameOrAfter(i: number, date: Moment, target: Moment) {
    return this.unitToMoment(i, target)
      .isSameOrAfter(date, this.levelToMomentUnit(this.level));
  }

  protected isToday(i: number, target: Moment) {
    return this.isSame(i, this.today, target);
  }

  protected unitToMoment(i: number, target: Moment): Moment {
    return target.clone().add(i - 5, this.levelToMomentUnit(this.level));
  }

  protected levelToMomentUnit(level: Level): unitOfTime.Base {
    if (level == Level.MONTH) {
      return "months";
    } else if (level == Level.WEEK) {
      return "weeks";
    } else {
      return "days"
    }
  }

  protected levelToMomentFormat(level: Level): string {
    if (level == Level.MONTH) {
      return "MMM";
    } else if (level == Level.WEEK) {
      return "W";
    } else {
      return "MMM D";
    }
  }

  private statusCount(status: ItemStatus, equipment: Equipment): number {
    if (equipment.items[0]?.serialNumber === "N/A") {
      return this.isItem(equipment.items[0], status) ? equipment.quantity : 0;
    } else {
      return equipment.items.filter(i => this.isItem(i, status)).length;
    }
  }

  private expandCollapseGroup(group: ProjectHeader, expand: boolean) {
    group.expanded = expand;

    const config = this.getGroupStateConfig();
    config.set(group.project.id, expand);
    const pathname = getCurrentPathname(this.router);
    localStorage.setItem(`groupState-${pathname}`, JSON.stringify(Object.fromEntries(config)))

    console.log("EquipmentTableComponent expandCollapseGroup", pathname, config);
  }

  private getGroupStateConfig(): Map<string, boolean> {
    const pathname = getCurrentPathname(this.router);
    const configRaw = localStorage.getItem(`groupState-${pathname}`) || "{}";
    return new Map<string, boolean>(Object.entries(JSON.parse(configRaw)));
  }

  private loadEquipment(pageLoad: boolean = false) {
    this.filterLoading = true;
    let filter = this.filterValue;

    const projects = this.advancedFilter.project.selected.length > 0
      ? this.advancedFilter.project.selected
      : this.projectIds;
    // only include children projects if no advanced filter is selected
    const includeChildrenProjects = this.advancedFilter.project.selected.length === 0;

    console.group("EquipmentTableComponent loadEquipment loading with options");
    console.log("equipmentGroup", this.equipmentGroup);
    console.log("projects", projects);
    console.log("projectIds", this.projectIds);
    console.log("text filter", filter);
    console.log("advancedFilter", this.advancedFilter);
    console.log("includeChildrenProjects", includeChildrenProjects);
    console.groupEnd();
    this.equipmentService
      .list(
        this.equipmentGroup, projects, [], filter,
        this.advancedFilter.status.selected, this.advancedFilter.responsible.selected,
        this.advancedFilter.manufacturer.selected, [], includeChildrenProjects
      )
      .subscribe(equipmentListResponse => {
        this.loadedByFilter = filter;
        this.filterLoading = false;

        // first load
        const projects = equipmentListResponse.projects;
        const equipment = equipmentListResponse.equipment
          .filter(e => this.filterByItemStatus(e, ItemStatus.IN_STOCK, this.advancedFilter.inStock.selected))
          .filter(e => this.filterByItemStatus(e, ItemStatus.PDI_DONE, this.advancedFilter.pdiDone.selected))
          .filter(e => this.filterByItemStatus(e, ItemStatus.READY_TO_SHIP, this.advancedFilter.readyToShip.selected))
          .filter(e => this.filterByItemStatus(e, ItemStatus.ON_CLIENT_PREMISES, this.advancedFilter.onClientPremises.selected))
          .filter(e => this.filterByItemStatus(e, ItemStatus.INSTALLED, this.advancedFilter.installed.selected))
          .filter(e => this.filterByAlert(e))
          .filter(e => this.filterBySelection(e))
          .filter(e => this.filterByDiffFromContract(e))
          .filter(e => this.filterByNotes(e))
          .filter(e => this.filterByComments(e));

        // advanced filter option population
        this.advancedFilter.responsible.options = _.uniq(
          equipment
            .flatMap(e => (e.responsible || []).concat(e.responsibleByManufacturer))
            .concat(this.advancedFilter.responsible.options.map(o => o.value))
            .concat(this.advancedFilter.responsible.selected)
        ).map(it => option(it));
        this.advancedFilter.manufacturer.options = _.uniq(
          equipment.map(e => e.manufacturer)
            .concat(this.advancedFilter.manufacturer.options.map(o => o.value))
            .concat(this.advancedFilter.manufacturer.selected)
        ).sort()
          .map(it => option(it));

        if (this.firstLoad || pageLoad) {
          this.firstLoad = false;
          if (projects.length > 0) {
            this.projects = projects;
          }
          if (this.context != Context.SERVICE_SPARE_PARTS) {
            const project = this.project;
            if (project) {
              populateChildren(project, projects);
              console.log("EquipmentTableComponent loadEquipment single project filter options", project);
              const optionsWithChildren = (project: Project, currentLevelName: string): CheckBoxOption[] => {
                const children = project.children.flatMap(c => optionsWithChildren(c, `${currentLevelName} / ${c.nameWithoutParents}`));
                return [{display: currentLevelName, value: project.id}, ...children];
              };
              this.advancedFilter.project.options = optionsWithChildren(project, ".");
            } else {
              this.advancedFilter.project.options =
                projects.map(p => {
                  return {display: p.name, value: p.id};
                }).sort((a, b) => a.display.localeCompare(b.display));
            }
          }
        }
        this.dataSource.data = this.addGroups(projects,
          equipment
            .map(e => {
              e.projectName = projects.find(p => p.id === e.projectId)?.nameWithoutParents || "";
              return e;
            })
            .sort((a, b) => a.model.localeCompare(b.model))
            .sort((a, b) => (a.projectCode || "").localeCompare((b.projectCode || ""), undefined, {numeric: true})));
        this.updateSelectionFromQueryParams();

        this.applyFilter();
        this._changeDetectorRef.markForCheck();
      });
  }

  private filterByAlert(e: Equipment): boolean {
    return this.advancedFilter.alerts.selected.length == 0
      || this.alerts()
        .filter(a => a.equipmentId === e.id)
        .map(a => a.type)
        .filter(alertType => this.advancedFilter.alerts.selected.includes(alertType))
        .length > 0;
  }

  private filterBySelection(e: Equipment): boolean {
    const sel = this.advancedFilter.selection.selected;
    return sel.length == 0
      || (!sel.includes(e.id));
  }

  private filterByDiffFromContract(e: Equipment): boolean {
    const sel = this.advancedFilter.diffFromContract.selected.map(v => v ? v : null);
    return sel.length == 0 && e.diffFromContract != DiffFromContract.YES_RESOLVED
      || sel.includes(e.diffFromContract);
  }

  private filterByNotes(e: Equipment): boolean {
    const sel = this.advancedFilter.notes.selected;
    return sel.length == 0
      || (sel.includes(HasFilter.HAS) && e.notes && e.notes.length > 0)
      || (sel.includes(HasFilter.DOESNT_HAVE) && !e.notes?.length);
  }

  private filterByComments(e: Equipment): boolean {
    const sel = this.advancedFilter.comments.selected;
    return sel.length == 0
      || (sel.includes(HasFilter.HAS) && e.commentIds?.length > 0)
      || (sel.includes(HasFilter.DOESNT_HAVE) && e.commentIds?.length === 0);
  }

  private filterByItemStatus(e: Equipment, status: ItemStatus, selected: string[]): boolean {
    if (selected.length > 0) {
      return (selected.includes(ItemStatusFilter.NOT_STARTED) && e.itemCountByStatus[status] == 0)
        || (selected.includes(ItemStatusFilter.IN_PROGRESS) && e.itemCountByStatus[status] > 0 && e.itemCountByStatus[status] < e.quantity)
        || (selected.includes(ItemStatusFilter.COMPLETE) && e.itemCountByStatus[status] == e.quantity);
    } else return true;
  }

  private applyFilter() {
    this.selection.selected.forEach(eq => this.dataSource.filteredData.includes(eq) || this.selection.deselect(eq));

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  private updateSelectionFromQueryParams(soft: boolean = false): void {
    if (this.selectionFromQueryParams) {
      this.selection.clear();
      this.selection.select(
        ...this.dataSource.data.filter(e =>
          !(e instanceof ProjectHeader) &&
          this.selectionFromQueryParams?.find(id => e.id == id))
      );
      this.advancedFilter.selection.selected = this.selectionFromQueryParams || [];
      if (!soft) {
        this.selectionFromQueryParams = undefined;
      }
    }
  }

  private addGroups(projects: Project[], equipment: Equipment[]): (ProjectHeader | Equipment)[] {
    if (this.context == Context.SINGLE_PROJECT || this.context == Context.SERVICE_SPARE_PARTS) return equipment;

    this.groups = [];
    const groupStateConfig = this.getGroupStateConfig();

    return projects.flatMap(project => {
      const group = new ProjectHeader(project);
      if (groupStateConfig.has(project.id)) {
        group.expanded = groupStateConfig.get(project.id) ?? true;
      }
      const projectEquipment = equipment.filter(e => e.projectId == project.id);
      if (projectEquipment.length > 0) {
        this.groups!.push(group);
        return [group,
          ...projectEquipment];
      } else {
        return [];
      }
    });
  }

  private tooltipHeaderDate(i: number) {
    const format = this.level == Level.WEEK ? "YYYY-MM-DD" :
      this.level == Level.DAY ? "dddd" : "YYYY";
    return format ? this.unitToMoment(i, this.target).format(format) : "";
  }

  private openEditMultipleDialog(): void {
    const dialogData: BulkEditDialogData = {
      equipment: this.selected
    }
    const dialogRef = this.dialog.open(BulkEditDialogComponent, {data: dialogData});
    this.dialogRef = dialogRef;
    dialogRef.afterClosed().subscribe(result => {
      console.log("EquipmentTableComponent multiple dialog afterClosed result", result);
      this.dialogRef = undefined;
      if (result)
        this.loadEquipment(false);
    });
  }

  private isItem(item: Item, status: ItemStatus): boolean {
    return item.statusHistory.find(sh => sh.status == status) !== undefined;
  }

  private restoreFilterSelection() {
    const configRaw = localStorage.getItem(`filterSelection`) || "[]";
    const config = JSON.parse(configRaw) as CheckBoxFilter[];
    config.forEach(c => this.advancedFilter[c.key].selected = c.selected);
  }

  private rememberFilterSelection() {
    const rememberedFilters = this.selectedFilters.filter(f => f.remember)
      .map(f => {
        const cloned = {...f} as CheckBoxFilter;
        cloned.options = []
        return cloned;
      });
    localStorage.setItem(`filterSelection`, JSON.stringify(rememberedFilters));
  }
}

export enum Level {
  MONTH, WEEK, DAY
}

export class ProjectHeader {
  projectHeader: boolean = true;
  project: Project;
  expanded = true;

  constructor(project: Project) {
    this.project = project;
  }
}

enum Context {
  SINGLE_PROJECT = "SINGLE_PROJECT",
  ACCOUNTING_ID = "ACCOUNTING_ID",
  ALL_EQUIPMENT = "ALL_EQUIPMENT",
  SERVICE_SPARE_PARTS = "SERVICE_SPARE_PARTS",
}

interface AdvancedFilters {
  alerts: CheckBoxFilter;
  selection: CheckBoxFilter;
  notes: CheckBoxFilter;
  comments: CheckBoxFilter;
  project: CheckBoxFilter;
  manufacturer: CheckBoxFilter;
  responsible: CheckBoxFilter;
  status: CheckBoxFilter;
  diffFromContract: CheckBoxFilter;
  inStock: CheckBoxFilter;
  pdiDone: CheckBoxFilter;
  readyToShip: CheckBoxFilter;
  onClientPremises: CheckBoxFilter;
  installed: CheckBoxFilter;

  [key: string]: CheckBoxFilter;
}

enum ItemStatusFilter {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETE = "COMPLETE",
}

enum HasFilter {
  HAS = "HAS",
  DOESNT_HAVE = "DOESNT_HAVE",
}

interface Column {
  key: string;
  label: string;
  visible: boolean;
  hidden: boolean | undefined;
}
