<div class="project-group">
  <!--    <a [routerLink]="group.projects[0] | url : false" class="group-name">{{group.accountingId}}</a>-->
  <div class="subgroups">
    <mat-tree [attr.data-parent]="null" [config]="sortableOptions" [dataSource]="dataSource"
              [nxtSortablejs]="dataSource.data" [treeControl]="treeControl" class="main-section project-tree">
      <!-- This is the tree node template for leaf nodes -->
      <!-- There is inline padding applied to this node using styles.
        This padding value depends on the mat-icon-button width. -->
      <mat-tree-node *matTreeNodeDef="let node" [id]="node.id" matTreeNodeToggle>
        <app-project-list-item (updated)="updated.next($event)" [alerts]="alerts" [editable]="editable"
                               [project]="node" [showDeadline]="showDeadline"></app-project-list-item>
        <div [attr.data-parent]="node.id" [config]="sortableOptions" [nxtSortablejs]="node.children"
             class="sortable-dropzone-new-child" role="group">
        </div>
      </mat-tree-node>
      <!-- This is the tree node template for expandable nodes -->
      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" [id]="node.id">
        <div class="mat-tree-node">

          <app-project-list-item (updated)="updated.next($event)" [alerts]="alerts" [editable]="editable"
                                 [project]="node" [showDeadline]="showDeadline"></app-project-list-item>
          <button (click)="toggleExpansionSelection(node)" [attr.aria-label]="'Toggle ' + node.name"
                  class="small-icon-button expand-button" mat-icon-button>
            <mat-icon class="mat-icon-rtl-mirror">
              {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
            </mat-icon>
          </button>
        </div>
        <!-- There is inline padding applied to this div using styles.
            This padding value depends on the mat-icon-button width.  -->
        <div [attr.data-parent]="node.id" [class.project-tree-invisible]="!treeControl.isExpanded(node)"
             [config]="sortableOptions" [nxtSortablejs]="" role="group">
          <ng-container matTreeNodeOutlet></ng-container>
        </div>
      </mat-nested-tree-node>
    </mat-tree>
  </div>
</div>
