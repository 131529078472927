<h2>Bulk Insert to {{ catalogGroupTitle() }} Catalog</h2>

<p>
  Provide a comma separated values (CSV) file in the format provided in
  <a [download]="exampleFilename()" [href]="exampleCsvData">this example file</a>. Make sure to use semicolon
  character (<strong>;</strong>) as separator.
</p>

<div>
  <input #csvInput (change)="csvInputChange($event)"
         accept=".csv" hidden="hidden" onclick="this.value=null" type="file"/>
  <button (click)="csvInput.click()" color="primary" mat-flat-button>Choose CSV file</button>
</div>

<p *ngIf="apiError">
  <mat-error class="api-error">
    {{ apiError }}
  </mat-error>
</p>

<div *ngIf="imported">
  <h2>Successful import</h2>
  <h3>Source file</h3>
  <p>
    filename: {{ imported.file.name }}<br/>
    size: {{ imported.file.size }} bytes
  </p>
  <h3>Imported {{ imported.equipmentCatalog.length }} items to equipment catalog</h3>
</div>

<h2>{{ catalogGroupTitle() }} Catalog</h2>
<p>
  <mat-form-field class="no-bottom filter with-floating-label">
    <mat-label>Text Search</mat-label>
    <input #filter (keyup)="search(getValue($event))"
           [value]="filterLoaded"
           matInput
           placeholder='Enter search criteria. Ex. Meyer Sound'
           type="text">
    <button (click)="filter.value = ''; search('')" aria-label="Clear" mat-icon-button matSuffix>
      <mat-spinner *ngIf="filterLoading" class="progress-spinner" diameter="24" matSuffix></mat-spinner>
      <mat-icon *ngIf="!filterLoading"
                [class.empty]="filter.value.length === 0">close
      </mat-icon>
    </button>
  </mat-form-field>
</p>

<table [dataSource]="catalog" class="mat-elevation-z8" mat-table>

  <ng-container matColumnDef="manufacturer">
    <th *matHeaderCellDef mat-header-cell>Manufacturer</th>
    <td *matCellDef="let element" mat-cell> {{ element.manufacturer }}</td>
  </ng-container>

  <ng-container matColumnDef="sku">
    <th *matHeaderCellDef mat-header-cell>SKU</th>
    <td *matCellDef="let element" mat-cell> {{ element.sku }}</td>
  </ng-container>

  <ng-container matColumnDef="model">
    <th *matHeaderCellDef mat-header-cell>Model</th>
    <td *matCellDef="let element" mat-cell> {{ element.model }}</td>
  </ng-container>

  <ng-container matColumnDef="description">
    <th *matHeaderCellDef mat-header-cell>Description</th>
    <td *matCellDef="let element" mat-cell> {{ element.description }}</td>
  </ng-container>

  <ng-container matColumnDef="hyperlink">
    <th *matHeaderCellDef mat-header-cell>Hyperlink</th>
    <td *matCellDef="let element" mat-cell> {{ element.hyperlink }}</td>
  </ng-container>

  <ng-container matColumnDef="delete">
    <th *matHeaderCellDef mat-header-cell></th>
    <td *matCellDef="let element" mat-cell>
      <a (click)="deleteItem(element)">
        <mat-icon class="small-icon" matPrefix>delete</mat-icon>
      </a>
    </td>
  </ng-container>

  <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
  <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>

  <ng-container matColumnDef="loadCount">
    <td *matFooterCellDef colspan="4" mat-footer-cell>
      @if (filterLoading) {
        <p>Loading...</p>
      } @else if (catalog.length > 0) {
        <p>Loaded {{ catalog.length }} catalog
          items<span *ngIf="filterLoaded.length > 0"> by filter "{{ filterLoaded }}"</span>.
          @if (catalog.length >= displayLimit) {
            There's more, try specifying more exact filter.
          }
        </p>
      } @else if (filterLoaded.length > 0) {
        <p>No catalog items found by filter "{{ filterLoaded }}".</p>
      } @else {
        <p>There's nothing here.</p>
      }
    </td>
  </ng-container>
  <tr *matFooterRowDef="['loadCount']" mat-footer-row></tr>
</table>
