import {effect, Injectable} from "@angular/core";
import {webSocket, WebSocketSubject} from 'rxjs/webSocket';
import {environment} from "../../environments/environment";
import {AuthService} from "./auth.service";
import {WebSocketReponseType, WebSocketResponse} from "./model/ws";
import {ReplaySubject} from "rxjs";
import {AlertDetailed} from "./model/alert";

@Injectable()
export class WebSocketService {
  public alertMessages$: ReplaySubject<AlertDetailed[]> = new ReplaySubject();
  private subject: WebSocketSubject<unknown> | undefined;

  constructor(private auth: AuthService) {
    effect(() => {
      const loggedIn = auth.isLoggedInSignal()?.value || false;
      console.log("WebSocketService isLoggedIn event eval to", loggedIn, this.subject);
      if (loggedIn && this.subject === undefined) {
        console.log("WebSocketService subscribing");
        this.subject = webSocket({
          url: environment.apiUrl + '/ws',
          // url: environment.apiUrl.replaceAll("http:", "https:") + '/ws',
          protocol: ["ws-v1", this.auth.getAuthorizationToken()],
        });
        this.subject.subscribe({
          next: msg => {
            console.log('WebSocketService message received: ', msg);
            const parsed = msg as WebSocketResponse;
            if (parsed.type === WebSocketReponseType.ALERTS) {
              this.alertMessages$.next(parsed.data);
            }
          }, // Called whenever there is a message from the server.
          error: err => console.log('WebSocketService error', err), // Called if at any point WebSocket API signals some kind of error.
          complete: () => console.log('WebSocketService connect complete') // Called when connection is closed (for whatever reason).
        });
      } else if (!loggedIn && this.subject) {
        this.subject.unsubscribe();
        console.log('WebSocketService unsubscribed');
      }
    });
  }
}
