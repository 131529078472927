.dashboard-container
  .dashboard-layout
    display: flex
    flex-direction: column

    .card
      margin: 12px
      width: 420px

    .row-1
      display: flex
      flex-direction: row

      .card
        flex: 1

        .dashboard-card-content a
          text-decoration: none

          &:hover
            text-decoration: underline

    .row-2
      display: inline-flex
      flex-wrap: wrap

  h3.snoozed-title
    margin: 24px 0 12px
