import {ChangeDetectorRef, Component} from '@angular/core';
import {Papa, ParseResult} from "ngx-papaparse";
import {Project, ProjectImport, ProjectStatus} from "../../api/model/project";
import {ProjectService} from "../../api/project.service";
import {HttpErrorResponse} from "@angular/common/http";
import {ApiError} from "../../api/model/common";
import {EMPTY} from "rxjs";
import {MatSnackBar} from "@angular/material/snack-bar";
import * as _ from "lodash";
import {EquipmentGroup, EquipmentStatus} from "../../api/model/equipment";
import moment from "moment";
import {ManufacturerService} from "../../api/manufacturer.service";

@Component({
  selector: 'app-project-import',
  templateUrl: './project-import.component.html',
  styleUrls: ['./project-import.component.sass']
})
export class ProjectImportComponent {
  exampleCsvData: string;
  apiError: string | undefined;

  imported: {
    file: File,
    projects: Project[],
  } | undefined;
  protected readonly JSON = JSON;
  private allManufacturers = this.manufacturerService.listSignal();

  constructor(
    private projectService: ProjectService,
    private papa: Papa,
    private manufacturerService: ManufacturerService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _snackBar: MatSnackBar,
  ) {
    const rows = [
      [
        "Accounting Id",
        "Project Name",
        "Project Status (optional)",
        "Project Code",
        "Manufacturer",
        "SKU",
        "Model",
        "Qty.",
        "Status (optional)",
        "Estimated ship by (optional)",
        "Received on (optional)",
        "Description (optional)",
        "Hyperlink (optional)",
      ],
      [
        "0000", // 0
        "Demo", // 1
        "PENDING", // 2
        "1.1", // 3
        "Sonus", // 4
        "40.287.039.01", // 5
        "EW-D ASA SPLITTER", // 6
        "6", // 7
        "PENDING_ORDER", // 8
        "2024-01-01", // 9
        "2024-01-01", // 10
        "Multiline description\nLine 2\nLine 3", // 11
        "https://sonus.lt/", // 12
      ],
    ];
    this.exampleCsvData = "data:text/csv;charset=utf-8," + encodeURI(papa.unparse(rows, {delimiter: ";"}));
    //rows.map(e => e.join(",")).join("\n");
  }

  csvInputChange($event: Event) {
    // TODO add loading spinner
    this.apiError = undefined;
    this.imported = undefined;
    const file = ($event.target as any).files[0] as File;
    file.text().then(contents => {
      // TODO make proper delimiter detection, so both comma and semicolon work
      const parsed = this.papa.parse(contents, {delimiter: ";", newline: "\r\n"});
      if (parsed.errors.length > 0) {
        this.apiError = parsed.errors.map(e => e.message).join("; ");
        return;
      }
      const request = this.parsedCsvToData(parsed);
      if (request.length === 0) {
        this.apiError = "Bad or empty .csv file submitted. Make sure you use semicolons as separators (;)";
        return;
      }
      const manufacturers = [...new Set([...request.flatMap(row => row.equipment.map(e => e.manufacturer))])];
      const allManufacturers = this.allManufacturers() || [];
      const newManufacturers = manufacturers.filter(m => allManufacturers.find(n => n.name === m) === undefined);
      console.log("ProjectImportComponent csvInputChange", $event, file, parsed, request, manufacturers, newManufacturers);
      if (newManufacturers.length === 0 || confirm(`You are about to create ${newManufacturers.length} manufacturer${newManufacturers.length > 1 ? "s" : ""}: ${newManufacturers.join(", ")}. Are you sure you want to continue?`)) {
        this.projectService.import(request).subscribe({
          next: (projects) => {
            console.log("ProjectImportComponent imported succesfully", projects);
            this.imported = {file, projects}
            this._snackBar.open(`Great success! ${projects.length} projects imported...`, undefined, {duration: 10000});
            this._changeDetectorRef.markForCheck();
          },
          error: error => {
            console.log("ProjectImportComponent API call failed", error)
            this.apiError = ((error as HttpErrorResponse).error as ApiError).message || (error as HttpErrorResponse).message;
            this._changeDetectorRef.markForCheck();
            return EMPTY;
          },
          complete: () => {
          }
        });
      }
    });
  }

  private parsedCsvToData(csv: ParseResult): ProjectImport[] {
    const csvNoHeaders = (csv.data.slice(1) as string[][]).filter(line => line.length > 2 && line[0] != "");

    const projects = csvNoHeaders.map(line => {
      return {
        accountingId: line[0],
        name: line[1],
        status: Object.values(ProjectStatus).find(s => s == line[2]) || ProjectStatus.PENDING,
        details: {location: {}},
        equipment: []
      } as ProjectImport
    });
    const projectSet = _.uniqBy(projects, p => p.accountingId + p.name);

    csvNoHeaders.forEach(line => {
      projectSet.find(p => p.accountingId == line[0] && p.name == line[1])?.equipment.push({
        group: EquipmentGroup.PROJECT,
        sku: line[5],
        manufacturer: line[4],
        model: line[6],
        description: line[11],
        hyperlink: line[12],
        projectCode: line[3],
        quantity: Number(line[7]),
        splitQty: 0,
        status: Object.values(EquipmentStatus).find(s => s == line[8]) || EquipmentStatus.PENDING_ORDER,
        responsible: [],
        diffFromContract: null,
        estimatedShipByDate: moment(line[9]).valueOf(),
        receivedOnDate: moment(line[10]).valueOf(),
        purchaseOrderNumber: null,
        purchaseOrderDate: null,
        salesOrderConfirmationNumber: null,
        salesOrderConfirmationDate: null,
        invoiceNumber: null,
        invoiceDate: null,
        installedDate: null,
        items: [],
      });
    });

    console.log("ProjectImportComponent parsedCsvToData project set", projectSet);

    return projectSet;
  }
}
