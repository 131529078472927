.project-list-filters
  display: inline-flex
  flex-wrap: wrap
  align-items: center
  gap: 12px
  padding-bottom: 12px

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content
    line-height: 36px

  .mat-button-toggle-disabled
    color: #ffffff99

  mat-form-field.filter
    .empty
      opacity: 0

.project-list-wrapper
  max-width: 500px

  mat-divider
    margin: 8px 0
